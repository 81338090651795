<template>
  <ReporteLayout
    titulo="Reportes - Ventas Detalladas"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipoTransaccion="venta"
  />
</template>

<script>
import ReporteLayout from "../../components/ReporteLayout.vue";
import ReporteVentasService from "../services";

export default {
  name: "ReporteVentasDetalladasLayout",
  components: {
    ReporteLayout,
  },
  data() {
    return {
      componenteListado: "ListadoTransaccionesDetalladas",
    };
  },
  methods: {
    servicioGetReporte: ReporteVentasService.getReporteVentasDetalladas,
    servicioGetReportePdf: ReporteVentasService.getReporteVentasDetalladasPdf,
  },
};
</script>